import React, { MutableRefObject } from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { Swiper } from 'Components/common/customSwiper';
import {
	SwiperRefActions,
	SwiperSlideChangedCbProps,
} from 'Components/common/customSwiper/interface';
import LazyComponent from 'Components/common/lazyComponent';

import { trackEvent } from 'Utils/analytics';
import PlatformUtils from 'Utils/platformUtils';
import { capitalizeOnlyFirstLetter } from 'Utils/stringUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';

const DummyProductCard = dynamic(
	() => import('Components/common/dummyProductCard'),
);
const ProductCardComponent = dynamic(
	() => import('Components/common/productCardComponent'),
);

const ListWrapper = styled.div`
	width: calc(100% + 1.5rem);
	height: 100%;
	overflow: visible;
	margin-top: 1.25rem;
	transform: translateX(-0.75rem);
`;

type OwnProps = {
	productIds: any[];
	showCityName?: boolean;
	renderImagesInServer?: boolean;
	elementsToShow?: number;
	elementsToSlide?: number;
	productCarouselRef?: MutableRefObject<SwiperRefActions | null>;
	onSlideChanged?: (args: Omit<SwiperSlideChangedCbProps, 'index'>) => void;
	parentIdentifier: string;
	arrowIconTopPosition?: string;
	sectionDataPayload?: Record<string, any>;
};

const ProductCardList = ({
	productIds = [],
	elementsToShow = 4,
	elementsToSlide = 4,
	showCityName,
	productCarouselRef,
	onSlideChanged,
	parentIdentifier = 'product-card-list',
	arrowIconTopPosition = '50%',
	sectionDataPayload = {},
}: OwnProps) => {
	const cardClassName = productIds.length
		? 'product-card-v2'
		: 'dummy-product-card';
	let productCardComponents = productIds.map((productId: any, index: any) => {
		const props = {
			id: productId,
			className: cardClassName,
			showCityName,
		};
		const shouldLazyLoad = !PlatformUtils.isDesktop()
			? index > 3
			: index > 7;
		return (
			<LazyComponent
				target={shouldLazyLoad ? 'USER' : 'NONE'}
				key={productId}
			>
				<ProductCardComponent
					{...props}
					isProductPage={true}
					cardIndex={index}
					parentIdentifier={parentIdentifier}
					sectionDataPayload={sectionDataPayload}
				/>
			</LazyComponent>
		);
	});
	// lateral list expects children as array;
	if (!productCardComponents.length) {
		productCardComponents = [1, 2, 3, 4].map(i => (
			<DummyProductCard key={i} />
		));
	}

	const handleSwiperArrowClick = (direction: 'forward' | 'backward') => {
		trackEvent({
			eventName: ANALYTICS_EVENTS.CAROUSEL.CHEVRON_CLICKED,
			[ANALYTICS_PROPERTIES.DIRECTION]:
				capitalizeOnlyFirstLetter(direction),
			...sectionDataPayload,
		});
	};

	return (
		<ListWrapper>
			<Swiper
				swiperRef={productCarouselRef}
				nextPrevControls={
					productCarouselRef && onSlideChanged ? 'hide' : 'show'
				}
				slidesToShow={elementsToShow}
				slidesToScrollBy={elementsToSlide || elementsToShow - 1}
				onSlideChanged={onSlideChanged}
				nextPrevControlPosX={'-0.8rem'}
				nextPrevControlPosY={arrowIconTopPosition}
				onChevronClick={handleSwiperArrowClick}
			>
				{productCardComponents}
			</Swiper>
		</ListWrapper>
	);
};

export default ProductCardList;
