import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Conditional from 'Components/common/conditional';
import Image from 'Components/common/image';
import Video from 'Components/common/video';

import { trackCityBannerClick } from 'Utils/analytics';
import { getBannerDimensions } from 'Utils/gen';
import { sanitiseURLProtocol } from 'Utils/imageUtils';
import { getCurrentPage } from 'Utils/stateUtils';

import type { BannerData } from 'ReduxTypes/banner';

import { LANDING_PAGE_BANNER_TYPES } from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const BannerTextContainer = styled.div<{ isVideo?: boolean }>`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 10.375rem;
	left: 4.875rem;
	${({ isVideo }) =>
		isVideo &&
		`
        width: 33.125rem;
        top: 1.375rem;
        left: 2.625rem;
    `}
	z-index: 2;
`;

const BannerHeading = styled.h1<{ isVideo?: boolean }>`
	color: ${colors.WHITE};
	${({ isVideo }) =>
		getFontDetailsByLabel(
			isVideo ? TYPE_LABELS.DISPLAY_LARGE : TYPE_LABELS.DISPLAY_REGULAR,
		)};
	max-width: 33.125rem;
	white-space: pre-wrap;
`;

const BannerSubHeading = styled.p`
	margin-top: 0.5rem;
	color: ${colors.WHITE};
	width: 37.5rem;
	white-space: initial;

	&.subheading {
		${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_LARGE)};
	}
`;

const SliderImageWrapper = styled.div`
	width: 100%;
	position: relative;
	height: 100%;
	overflow: hidden;
	border-radius: 0.75rem;

	.video-container {
		height: 31.25rem;
	}
`;

type Props = {
	banner: BannerData;
	isActive?: boolean;
	renderImage?: boolean;
	cityDisplayName: string;
	bannerIndex: number;
};

const Banner = ({
	banner,
	isActive = false,
	renderImage = false,
	cityDisplayName,
	bannerIndex,
}: Props) => {
	const { currentPage }: { currentPage: string } = useSelector(state => ({
		currentPage: getCurrentPage(state),
	}));

	const { url, type, metadata, info } = banner;
	const { altText: bannerAltText } = metadata;
	const { videoFallbackUrl, imageRedirectUrl } = info;

	const altText =
		bannerAltText ||
		strings.formatString(strings.BANNER_CITY.SUB_HEADING, cityDisplayName);
	const isVideo = type === LANDING_PAGE_BANNER_TYPES.VIDEO;
	const { width, height } = getBannerDimensions(currentPage);

	const style = {
		display: 'block',
	};
	const sliderClassNames = classNames({
		active: isActive,
	});

	return (
		// @ts-expect-error TS(2769): No overload matches this call.
		<SliderImageWrapper
			style={style}
			as={imageRedirectUrl ? 'a' : 'div'}
			href={
				imageRedirectUrl ? sanitiseURLProtocol(imageRedirectUrl) : null
			}
			rel={imageRedirectUrl ? 'noreferrer noopener' : null}
			target={imageRedirectUrl ? '_blank' : null}
			className={`slider-image-wrapper ${sliderClassNames}`}
			onClick={() =>
				trackCityBannerClick({
					index: bannerIndex,
					link: imageRedirectUrl
						? sanitiseURLProtocol(imageRedirectUrl)
						: undefined,
				})
			}
		>
			<Conditional if={bannerIndex === 0}>
				<BannerTextContainer isVideo={isVideo}>
					<BannerHeading isVideo={isVideo}>
						{strings.formatString(
							isVideo
								? strings.BANNER_CITY.VIDEO_HEADING
								: strings.BANNER_CITY.HEADING,
							cityDisplayName,
						)}
					</BannerHeading>
					{!isVideo && (
						<BannerSubHeading className='subheading'>
							{strings.formatString(
								strings.BANNER_CITY.SUB_HEADING,
								cityDisplayName,
							)}
						</BannerSubHeading>
					)}
				</BannerTextContainer>
			</Conditional>
			{isVideo ? (
				<Video
					url={url}
					isLooped
					isMuted
					shouldAutoPlay={true}
					currentlyPlaying={isActive}
					isBanner
					width={width}
					height={500}
					fallbackImage={{
						url: videoFallbackUrl,
						altText,
					}}
					responsive
					aria={{
						label: altText,
					}}
					imageDensity={1.2}
					priortizeImage={bannerIndex === 0}
				/>
			) : (
				<Image
					src={url}
					height={height}
					width={width}
					layout={'responsive'}
					priority={renderImage}
					alt={altText}
					density={1.2}
				/>
			)}
		</SliderImageWrapper>
	);
};

export default Banner;
