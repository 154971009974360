import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const ViewAllText = styled.span`
	&.view-all-link-text {
		color: ${colors.GREY['44']};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
		text-decoration: underline;
	}
`;

type OwnProps = {
	to?: string;
	target?: string;
	href?: string;
	text?: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ViewAllLink.defaultProps;

// @ts-expect-error TS(7022): 'ViewAllLink' implicitly has type 'any' because it... Remove this comment to see the full error message
const ViewAllLink = ({ to, target, text }: Props) => (
	<Link
		prefetch={false}
		href={to}
		rel='noopener noreferrer'
		target={target ?? to}
	>
		<ViewAllText className='view-all-link-text'>
			{text || strings.CMN_SEE_ALL}
		</ViewAllText>
	</Link>
);

ViewAllLink.defaultProps = {
	target: '_blank',
};

export default ViewAllLink;
