import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Swiper } from 'Components/common/customSwiper';
import { SwiperSlideChangedCbProps } from 'Components/common/customSwiper/interface';
import { SlidesWrapper } from 'Components/common/customSwiper/styles';
import Banner from 'Components/desktop/banner';

import { getBanners } from 'Utils/stateUtils';

import { BANNERS_PLATFORM } from 'Constants/constants';

const FeedPageBanner = styled.div<{
	noOfSlides?: number;
	dotsPosition?: number;
}>`
	position: relative;
	width: 75rem;
	height: 31.25rem;
	position: relative;
	margin: 0.5rem auto 0 auto;
	border-radius: 0.75rem;
	overflow: hidden;

	img,
	video {
		height: 31.25rem !important;
	}

	.next-button-wrapper,
	.prev-button-wrapper {
		top: 15.625rem;
	}

	${SlidesWrapper} {
		overflow-y: hidden;
	}
`;

type Props = {
	cityDisplayName: string;
};

const BannerCarousel = ({ cityDisplayName }: Props) => {
	const { banners } = useSelector(state => ({
		banners: getBanners({ state, platform: BANNERS_PLATFORM.DESKTOP }),
	}));
	const [currentIndex, setCurrentIndex] = useState(0);

	if (banners.length === 1) {
		return (
			<FeedPageBanner id='to-get-li-width' className='feed-page-banner'>
				<Banner
					isActive
					banner={banners?.[0]}
					renderImage
					cityDisplayName={cityDisplayName}
					bannerIndex={0}
				/>
			</FeedPageBanner>
		);
	}

	const onSlideChanged = ({ index }: SwiperSlideChangedCbProps) => {
		setCurrentIndex(index);
	};

	return (
		<FeedPageBanner
			id='to-get-li-width'
			className='feed-page-banner'
			noOfSlides={banners.length}
		>
			<Swiper
				autoPlay
				nextPrevControls='show'
				loop
				paginationDots
				onSlideChanged={onSlideChanged}
				hasRoundedCorners
			>
				{banners.map((banner: any, i: number) => (
					<Banner
						key={i}
						isActive={currentIndex === i}
						banner={banner}
						renderImage={i === 0}
						cityDisplayName={cityDisplayName}
						bannerIndex={i}
					/>
				))}
			</Swiper>
		</FeedPageBanner>
	);
};

export default BannerCarousel;
